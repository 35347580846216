import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../../store/actions";
import mudeyLogoYellow from "~images/mudey_logo_yellow.svg";
import { SidebarItem } from "./sidebarItem";
import { persistor } from "../../store";
import "./index.less";

const PROMOTERS = "PROMOTERS";


const SideBar = () => {
  const dispatch = useDispatch();
  const [mobileMenu, setMobileMenu] = useState(false);

  const userData = useSelector((state: any) => state?.userData);

  const isPartnerVerified = userData?.verified == "VERIFIED";
  const partnershipType = userData?.partnershipType;

  return (
    <div className={"side-menu " + (mobileMenu ? "" : "hidden-list")}>
      <div className="logo-wrapper-sidebar">
        <img src={mudeyLogoYellow} className="logo-sidebar" />
      </div>
      <img
        alt=""
        className="menu-trigger"
        onClick={() => {
          setMobileMenu(!mobileMenu);
        }}
        src={require("../../images/icons/bars.svg")}
      />
      <div
        className="logo"
        style={{ backgroundImage: `url(${mudeyLogoYellow})` }}
      />
      <div className={"navigation " + (mobileMenu ? "" : "hidden-sm")}>
        <ul>
          <SidebarItem
            to="/dashboard"
            icon={require("../../images/icons/icon5u.svg")}
            text="Dashboard"
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            disabledSidebarBtn={isPartnerVerified}
          />
          {partnershipType !== PROMOTERS && (
            <>
              {" "}
              <SidebarItem
                to="/clientes"
                icon={require("../../images/icons/icon2u.svg")}
                text="Clientes"
                mobileMenu={mobileMenu}
                setMobileMenu={setMobileMenu}
                disabledSidebarBtn={isPartnerVerified}
              />
              <SidebarItem
                to="/simulações"
                icon={require("../../images/icons/icon6u.svg")}
                text=" Simulações"
                mobileMenu={mobileMenu}
                setMobileMenu={setMobileMenu}
                disabledSidebarBtn={isPartnerVerified}
              />
              <SidebarItem
                to="/seguros"
                icon={require("../../images/icons/icon1u.svg")}
                text="Apólices"
                mobileMenu={mobileMenu}
                setMobileMenu={setMobileMenu}
                disabledSidebarBtn={isPartnerVerified}
              />
              <SidebarItem
                to="/recibos"
                icon={require("../../images/icons/receipt_w_t_60.svg")}
                text="Recibos"
                mobileMenu={mobileMenu}
                setMobileMenu={setMobileMenu}
                disabledSidebarBtn={isPartnerVerified}
              />
              <SidebarItem
                to="/venda-cruzada"
                icon={require("../../images/icons/cross_sale_w_t_60.svg")}
                text="Venda Cruzada"
                mobileMenu={mobileMenu}
                setMobileMenu={setMobileMenu}
                disabledSidebarBtn={isPartnerVerified}
              />
            </>
          )}
          <SidebarItem
            to="/cotar-seguros"
            icon={require("../../images/icons/buy.svg")}
            text="Cotar seguros"
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            disabledSidebarBtn={isPartnerVerified}
          />

          <SidebarItem
            to="/dados"
            icon={require("../../images/icons/data_w_t_60.svg")}
            text="Dados"
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            disabledSidebarBtn={isPartnerVerified}
          />
          {partnershipType !== PROMOTERS && (
            <SidebarItem
              to="/configuracoes"
              icon={require("../../images/clients.svg")}
              text="Configurações"
              mobileMenu={mobileMenu}
              setMobileMenu={setMobileMenu}
              disabledSidebarBtn={isPartnerVerified}
            />
          )}
        </ul>
      </div>
      <a
        href="http://forum.mudey.pt/"
        target="_blank"
        without="true"
        rel="noopener noreferrer"
        className={mobileMenu ? "" : "hidden-sm"}
        style={{
          textAlign: "center",
          color: "white",
          fontSize: "14px",
          fontFamily: "Brandon Grotesque Regular",
          cursor: "pointer",
        }}
      >
        → Aceder ao Forum
      </a>

      <div
        className={"logout " + (mobileMenu ? "" : "hidden-sm")}
        onClick={() => {
          dispatch({ type: LOGOUT });
          persistor.purge();
        }}
      >
        <img alt="" src={require("../../images/icons/logout.svg")} />{" "}
        <span style={{ fontFamily: "Brandon Grotesque Regular" }}>Sair</span>
      </div>
    </div>
  );
};

export default SideBar;
